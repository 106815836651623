import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ipImage} from "../../../model/ipImage";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ImageService} from "../../services/image.service";
import {GamePageService} from "../../swagger/api/gamePage.service";
import {SnackBarService} from "../../services/snack-bar.service";
import {IndiepassCodesEnum} from "../../../model/enums/indiepass-codes-enum";
import {ActionsEnum} from "../../snack-bar/enum-actions";
import {PannelsClassEnum} from "../../snack-bar/pannels-class-enum";

export interface uploadModalData {
  screenshots: ipImage[];
  idJeu: number;
  idGamePage: number;
}

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  listeImages: ipImage[] = [];
  @Output() newItemEvent = new EventEmitter<ipImage[]>();
  uploadProgress = 0;
  public numberOfImages = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: uploadModalData, private http: HttpClient,
              public gamePageService: GamePageService, public snackBarService : SnackBarService,
              public dialogRef: MatDialogRef<UploadImageComponent>, private imageService: ImageService) {
  }


  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.data.screenshots.forEach((element) => {
      let item: ipImage = {
        fileName: element.fileName
      }
      this.ajouterUImage(item)
      this.numberOfImages = this.data.screenshots.length;

    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ajouterUImage(item: ipImage) {
    if (item.file) {
      let fileToUpload = new File([item.file], "name");
      this.uploader(fileToUpload, "image" + 6)
    } else if (item.fileName) {
      item.id = this.listeImages.length
      this.listeImages.push(item)
    }


  }

//check image height and width
  checkImageSize(file: File) {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width === 1280 && img.height === 720) {
          resolve();
        } else {
          this.snackBarService.openSnackBar(IndiepassCodesEnum.IMAGE_SIZE_KO, ActionsEnum.Ok, PannelsClassEnum.error)
          reject();
        }
      };
    });
  }

  uploader(file: File, type: string) {
    this.checkImageSize(file).then(() => {
      const headers = new HttpHeaders().set('Content-Type', 'image/jpeg');
      this.gamePageService.uploadGamePageRessource(this.data.idGamePage, type).subscribe(data => {
        let oui: any = data;
        let path = oui.path
        let item: ipImage = {fileName: path, percentage: 0}
        this.listeImages.push(item)
        this.imageService.uploadImage(file, oui.linkUpload, headers).subscribe(
          (progress) => {
            // @ts-ignore
            this.listeImages.find((image) => image.fileName === path).percentage = progress
            this.uploadProgress = progress;
          },
          (error) => {
            // @ts-ignore
            delete this.listeImages.find((image) => image.fileName === path)
          },
          () => {
            // @ts-ignore
            this.listeImages.find((image) => image.fileName === path).loading = true;
            this.uploadProgress = 0;
            item = {fileName: path}
            // @ts-ignore
            this.checkImage(this.listeImages.find((image) => image.fileName === path));
            // @ts-ignore
            delete this.listeImages.find((image) => image.fileName === path).percentage


          }
        );

      });
    });
  }

  checkImage = (image: ipImage) => {
    const img = new Image();
    img.src = environment.urlimgcdn + this.data.idJeu  + "/" + image?.fileName + "?class=screenshot";
    img.onload = () => {
      image.loading = false;
    };
    img.onerror = () => {
      setTimeout(this.checkImage, 2500, image); // Réessayez après 5 secondes
    };
  };

  deleteElement(fileName: string) {
    // @ts-ignore
    this.listeImages.splice(this.listeImages.indexOf(this.listeImages.find((image) => image.fileName === fileName)), 1)
    this.numberOfImages--;
  }

  refreshArayIndex() {
    let i: number = 0;
    this.listeImages.forEach(obj => {
      obj.id = i;
      i++;
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listeImages, event.previousIndex, event.currentIndex);
    this.refreshArayIndex();
  }

  imageUpload(event: any) {

    if (event.target.files && event.target.files.length) {
      const files: File[] = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.numberOfImages++;
        if (this.numberOfImages <= 7) {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          reader.onload = () => {
            let item: ipImage = {
              fileName: reader?.result + "",
              file: files[i]
            }
            this.ajouterUImage(item)
          }
        }
      }

    }
  }

  areAllImagesLoaded(): boolean {
    return this.listeImages.every(image => !image.loading && (image.percentage === undefined || image.percentage === null));
  }
  readonly environment = environment;
}
